import React, { Component } from "react"
import AlbumPricingSlice from "../../components/albumPricingSlice"
import Page from "../../components/page"
import pageStyles from "../../components/page.module.css"
import "../../../public/css/modules.min.css"

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      measurement: "CM",
      currency: "USD",

      priceList: null,
      config: null,
    }

    this.product = "album"
  }

  componentDidMount() {
    fetch("https://shop.phototailors.com/api/public/prices", {})
      .then(response => response.json())
      .then(prices => {
        fetch(
          "https://shop.phototailors.com/api/public/prices/config/boudoir",
          {}
        )
          .then(response => response.json())
          .then(config => {
            this.setState({ priceList: prices, config: config })
          })
      })
  }

  render() {
    if (!this.state.priceList) {
      return null
    }

    const albums = this.getAlbums()

    return (
      <Page
        title={"Album Pricing – PhotoTailors"}
        location={this.props.location}
      >
        <div className={pageStyles.section}>
          <div className="filter-options">
            <div className="units">
              <p>Measurements</p>
              <ul>
                <li>
                  <button
                    type="button"
                    onClick={e => this.setState({ measurement: "CM" })}
                    className="qodef-btn qodef-btn-small qodef-btn-solid qodef-dir-aware-hover"
                    data-type="plus"
                    data-field="spreads-no"
                  >
                    <span className="qodef-btn-text">CM</span>
                    <span className="qodef-btn-background-holder">
                      <span
                        className="qodef-btn-background"
                        style={{ top: "-4px; left: 169.717px" }}
                      ></span>
                    </span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={e => this.setState({ measurement: "INCH" })}
                    className="qodef-btn qodef-btn-small qodef-btn-solid qodef-dir-aware-hover"
                    data-type="plus"
                    data-field="spreads-no"
                  >
                    <span className="qodef-btn-text">INCH</span>
                    <span className="qodef-btn-background-holder">
                      <span
                        className="qodef-btn-background"
                        style={{ top: "-4px; left: 169.717px" }}
                      ></span>
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            <div className="currency">
              <p>Currency</p>
              <ul>
                <li>
                  <button
                    type="button"
                    onClick={e => this.setState({ currency: "USD" })}
                    className="qodef-btn qodef-btn-small qodef-btn-solid qodef-dir-aware-hover"
                    data-type="plus"
                    data-field="spreads-no"
                  >
                    <span className="qodef-btn-text">USD</span>
                    <span className="qodef-btn-background-holder">
                      <span
                        className="qodef-btn-background"
                        style={{ top: "-4px; left: 169.717px" }}
                      ></span>
                    </span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={e => this.setState({ currency: "EUR" })}
                    className="qodef-btn qodef-btn-small qodef-btn-solid qodef-dir-aware-hover"
                    data-type="plus"
                    data-field="spreads-no"
                  >
                    <span className="qodef-btn-text">EUR</span>
                    <span className="qodef-btn-background-holder">
                      <span
                        className="qodef-btn-background"
                        style={{ top: "-4px; left: 169.717px" }}
                      ></span>
                    </span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={e => this.setState({ currency: "GBP" })}
                    className="qodef-btn qodef-btn-small qodef-btn-solid qodef-dir-aware-hover"
                    data-type="plus"
                    data-field="spreads-no"
                  >
                    <span className="qodef-btn-text">GBP</span>
                    <span className="qodef-btn-background-holder">
                      <span
                        className="qodef-btn-background"
                        style={{ top: "-4px; left: 169.717px" }}
                      ></span>
                    </span>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    onClick={e => this.setState({ currency: "AUD" })}
                    className="qodef-btn qodef-btn-small qodef-btn-solid qodef-dir-aware-hover"
                    data-type="plus"
                    data-field="spreads-no"
                  >
                    <span className="qodef-btn-text">AUD</span>
                    <span className="qodef-btn-background-holder">
                      <span
                        className="qodef-btn-background"
                        style={{ top: "-4px; left: 169.717px" }}
                      ></span>
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <form
            id="pricing-table"
            className="Rtable Rtable--3cols Rtable--collapse"
            method="post"
          >
            {albums}
          </form>
        </div>
      </Page>
    )
  }

  getAlbums = () => {
    return this.state.config.map(item => {
      return (
        <AlbumPricingSlice
          data={item}
          product={this.product}
          prices={this.state.priceList}
          currency={this.state.currency}
          measurement={this.state.measurement}
        />
      )
    })
  }
}
